import { useState } from 'react';

export const Social = () => {
    const [isActive, setActive] = useState<boolean>(false);

    const toggle = () => setActive(!isActive);
    const c = ['Footer-LinksItem', isActive ? 'active' : ''].join(' ');

    return (
        <div className='social-menu'>
            <div className={c}>
                <h3 onClick={toggle}>Соціальні мережі:</h3>
                <ul className='social-menu'>
                    <li>
                        <a
                            className='tiktok'
                            href='https://www.tiktok.com/@danceex_store_'
                            target='_blank'
                            rel='noopener'
                        >
                            tiktok
                        </a>
                    </li>
                    <li>
                        <a
                            className='facebook'
                            href='https://www.facebook.com/Danceexstore'
                            target='_blank'
                            rel='noopener'
                        >
                            facebook
                        </a>
                    </li>
                    <li>
                        <a
                            className='insta'
                            href='https://www.instagram.com/danceex_store/'
                            target='_blank'
                            rel='noopener'
                        >
                            instagram
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};
