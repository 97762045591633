export type Mods = Record<string, string | boolean | undefined>;

export const classNames = (
    cls: string,
    mods: Mods = {},
    additional: Array<string | undefined> = []
) => {
    const _mods = Object.entries(mods)
        .filter(([_, value]) => Boolean(value)) // eslint-disalbe-line
        .map(([className]) => className);

    return [cls, ..._mods, ...additional.filter(Boolean)].join(' ');
};
