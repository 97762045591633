import { ReactNode } from 'react';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from '../config/store';

interface StoreProviderProps {
    children: ReactNode;
}

export const ReduxStoreByOfficialDocProvider = (props: StoreProviderProps) => {
    const { children } = props;

    return (
        <Provider store={store}>
            <PersistGate loading={<LoadingState />} persistor={persistor}>
                {children}
            </PersistGate>
        </Provider>
    );
};

const LoadingState = () => {
    return <div>Loading PersistGate...</div>;
};
