import './Image.css';

import { Link } from 'react-router-dom';
import { TypeProductComponent } from 'src/fsd/shared/types';

import { getIsAdmin } from '@/const';
import { RoutesNames } from '@/routes/RoutesName';

import { SimpleBadge } from './SimpleBadge';

export const Image = ({ product }: TypeProductComponent) => {
  return (
    <div className='ProductCard-Image'>
      <Link tabIndex={0} to={RoutesNames.ProductPage + `${product.id}`}>
        <div className='wrap-imag-box'>
          <div className='img-box'>
            {getIsAdmin() && <SimpleBadge product={product} />}
            <img
              className='img-box--image'
              // 300x450
              src={
                product.thumbnail_url
                  ? product.thumbnail_url + '?w=530&h=798&fit=crop&v=1' // fit=crop робить чорну полоску для jpg
                  : '' // product.thumbnail_url + '?w=5&h=10&v=1' // '?w=5&h=10&fit=crop&v=1'
              }
              alt='Мініатюра товару'
              loading='lazy'
            />
          </div>
        </div>
      </Link>
    </div>
  );
};
