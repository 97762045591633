import {
  IFiltersRTKQ,
} from '@/fsd/entities/ProductFilters/model/types/filtersRTKQType';

// export function restoreProductsFilterFromUrl() {
//     let str = localStorage.getItem('persist:root');
//     if (str) {
//         const persistRoot = JSON.parse(str);
//         if (persistRoot?.productsFiltersReducer) {
//             const productsFiltersReducer: ProductsFiltersSchema = JSON.parse(
//                 persistRoot.productsFiltersReducer
//             );

//             const filtersRTKQ = getUpdatedFiltersByURL(
//                 productsFiltersReducer.filtersRTKQ
//             );
//             console.log(filtersRTKQ);

//             productsFiltersReducer.filtersRTKQ = filtersRTKQ;
//             console.log(persistRoot);
//             persistRoot.productsFiltersReducer = JSON.stringify(
//                 productsFiltersReducer
//             );

//             str = JSON.stringify(persistRoot);

//             localStorage.setItem('persist:root', str);
//             console.log(persistRoot);
//         }
//     }
// }

export function getUpdatedFiltersByURL(
    filtersRTKQ: IFiltersRTKQ,
    searchParam: URLSearchParams
) {
    // const locationSearch = window.location.hash.substring(
    //     window.location.hash.indexOf('?')
    // );
    // const query = new URLSearchParams(locationSearch);

    const query = searchParam;

    let isFilterChanged = false;

    for (const key of query.keys()) {
        // console.log(key, query.get(key));
        if (key in filtersRTKQ) {
            const ids = query.get(key)?.split(',');
            ids?.forEach((id) => {
                const index = filtersRTKQ[key].values.findIndex(
                    (e) => e.id === +id
                );

                if (index > -1) {
                    filtersRTKQ[key].values[index].checked = true;
                    isFilterChanged = true;
                }
            });
        }
    }

    return { isFilterChanged, filtersRTKQ };
}
