import { useAppSelectorWithTypes } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { getCart } from '@/fsd/entities/cart';

import { RenderRows } from '../widgets/RenderRows';

export const CheckoutPage = () => {
  const cartRaw = useAppSelectorWithTypes(getCart);

  if (cartRaw.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          flexDirection: 'column',
          gap: 16,
          paddingBottom: 24,
        }}
      >
        <h2>Корзина порожня.</h2>
        <h3>Для оформення замовлення, будь ласка, додайте товари в коризину</h3>
      </div>
    );
  }

  return <CheckRequirements />;
};

function CheckRequirements() {
  return <RenderRows />;
}
