import { Link } from 'react-router-dom';

import { useAppSelector } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { CartItemType } from '@/fsd/entities/cart/model/types/CartSchema';
import { cartProductsSelectors } from '@/fsd/entities/cartProducts/model/selectors/cartProductsSelectors';
import { ActionButtons } from '@/fsd/shared/ui/Cart/actions/ActionButtons';
import {
  getOfferByProdIdInCart,
  getProductByProdIdInCart,
} from '@/fsd/shared/ui/Cart/cartUtils';
import { TheSameGood } from '@/fsd/shared/ui/commons/TheSameGood';
import { RoutesNames } from '@/routes/RoutesName';

import { Offer } from '../../utils/Offer';
import cls from './CustomCartItem.module.scss';

export function CustomCartItem({
  idx,
  cartItem,
}: {
  idx: number;
  cartItem: CartItemType;
}) {
  const cartProducts = useAppSelector(cartProductsSelectors.products);

  const product = getProductByProdIdInCart(cartProducts, cartItem);

  if (!product) {
    return (
      <li>
        <span className={cls['Product-Row']}>
          <span>{idx + 1}</span>
          На сервері не знайдено товар з кодом {cartItem.item.product_id}.
          Очевидно такий товар раніше був на сервері, але зараз немає.
        </span>
      </li>
    );
  }

  const offer = getOfferByProdIdInCart(product, cartItem);
  const color = product?.danceex_options.color || '';

  return (
    <li>
      <span className={cls['Product-Row']}>
        <span>{idx + 1}</span>

        <Link to={RoutesNames.ProductPage + `${product.id}`}>
          <span
            style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                maxWidth: '112px',
                flexShrink: '0',
              }}
            >
              <TheSameGood product={product} />
            </div>
            {/* {product?.name} (код: {product?.id}) */}
            {product?.name}
          </span>
        </Link>

        <span>{color.length > 0 && <>{color}</>}</span>
        <span>{offer && <Offer.PropertiesList offer={offer} />}</span>
        <span>Ціна {offer?.price} ₴</span>
        <ActionButtons cartItem={cartItem} />
      </span>
    </li>
  );
}
