import { TypeProduct } from '@/fsd/shared/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CartProductsSchema } from '../types/CartProductsSchema';

const initialState: CartProductsSchema = {
  products: [],
};

export const cartProductsSlice = createSlice({
  name: 'cartProducts',
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<TypeProduct[]>) => {
      state.products = action.payload;
    },
    setCurrentProduct: (
      state,
      action: PayloadAction<TypeProduct | undefined>
    ) => {
      state.currentProduct = action.payload;
    },
  },
});

export const {
  actions: cartProductsSliceActions,
  reducer: cartProductsSliceReducer,
} = cartProductsSlice;
