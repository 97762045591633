import { TypeProductComponent } from '../../types';

export function TheSameGood({ product }: TypeProductComponent) {
  const src = product.thumbnail_url
    ? product.thumbnail_url + '?w112&h=168' // '?w112&h=168&fit=crop&v=1'
    : '';

  return (
    <picture>
      <source srcSet={src} type='image/jpeg' />
      <source srcSet={src} />
      <img src={src} alt={product.name} loading='lazy' />
    </picture>
  );
}
