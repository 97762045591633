import { Link } from 'react-router-dom';

import { FILTERS_NAME } from '@/const';
import {
  AgeItemType,
  AgeItemTypeMenu,
} from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter/types/AgeItemType';
import { CaretDownIcon } from '@radix-ui/react-icons';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';

interface ItemMenuAndSubItemsProps {
  item: AgeItemType;
  subItems: AgeItemType[];
  // itemCategory: AgeItemType | undefined;
}

/**
 * Елемент меню та його підменю
 * @param props
 * @returns
 */
export function RadixItemMenuAndSubItems(props: ItemMenuAndSubItemsProps) {
  const { item } = props;
  // const { item, itemCategory } = props;
  // const { onClickMenuItem } = useClickMenuItem();
  // const navigate = useNavigate();

  const _item = item as AgeItemTypeMenu;

  return (
    <>
      <NavigationMenu.Item>
        <Link
          to={_item.link}
          className='NavigationMenuLink'
          style={{ padding: 0 }}
          // onClick={() => {
          //   // navigate('/?' + FILTERS_NAME.PRODUCT_CATEGORY + '=' + _item.id)
          //   itemCategory && onClickMenuItem(itemCategory as AgeItemTypeMenu);
          // }}
        >
          <NavigationMenu.Trigger className='NavigationMenuTrigger'>
            {item.name}
            <CaretDownIcon className='CaretDown' aria-hidden />
          </NavigationMenu.Trigger>
        </Link>
        <_SubItems {...props} />
      </NavigationMenu.Item>
    </>
  );
}

const _SubItems = (props: ItemMenuAndSubItemsProps) => {
  const { subItems } = props;
  // const navigate = useNavigate()
  // const { onClickMenuItem } = useClickMenuItem();

  return (
    <NavigationMenu.Content className='NavigationMenuContent'>
      <ul className='List two'>
        {subItems.map((item, _idx) => {
          const _item = item as AgeItemTypeMenu;

          return (
            <NavigationMenu.Item key={_idx}>
              <Link
                to={'/?' + FILTERS_NAME.PRODUCT_CATEGORY + '=' + _item.id}
                className='NavigationMenuLink'
                // onClick={() => {
                //   // navigate('/?' + FILTERS_NAME.PRODUCT_CATEGORY + '=' + _item.id)
                //   onClickMenuItem(_item);
                // }}
              >
                {_item.name}
              </Link>
            </NavigationMenu.Item>
          );
        })}
      </ul>
    </NavigationMenu.Content>
  );
};
