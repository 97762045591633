import { useNavigate, useSearchParams } from 'react-router-dom';

// import { useNavigate } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
} from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { mainMenuActions } from '@/fsd/entities/MainMenu';
import { productsFiltersActions } from '@/fsd/entities/ProductFilters';
import { getProductFiltersRTKQ } from '@/fsd/entities/ProductFilters/model/selectors/productsFiltersSelectors';
import { buildKeyValueQueryParamsByFiltersRTKQ } from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/buildKeyValueQueryParamsByFiltersRTKQ';
import { IFiltersRTKQ } from '@/fsd/entities/ProductFilters/model/types/filtersRTKQType';
import { AgeItemType } from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter';

import { ChooseColorProps } from '../../impl/Aside/shared/components/ChooseColor/ui/ChooseColorProps';
import { UpdateFiltersRTKQProps } from './types/UpdateFiltersRTKQProps';

export const useProductFilters = () => {
  const dispatch = useAppDispatch();
  const filtersRTKQ = useAppSelector(getProductFiltersRTKQ);
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const clearAndUpdateFiltersByKeyRTKQ = ({
    key,
    item,
  }: UpdateFiltersRTKQProps) => {
    let newValues: AgeItemType[];

    // 2do: Помилка роутингу
    /**
     * Якщо в браузері відркрити сторінку Корзина (не відкриваючи головну сторінку), тоді об'єкт filtersRTKQ undefined
     * Виникає помилка
     */
    newValues = filtersRTKQ[key].values.map((_: AgeItemType) =>
      _.id === item.id
        ? { ...item }
        : {
            ..._,
            checked: false,
          }
    );

    const newFilters = _getNewFilters({ key, newValues });
    _commonUpdate({ newFilters });
  };

  // const clearFiltersByKeyRTKQ = ({
  //     key,
  // }: Pick<UpdateFiltersRTKQProps, 'key'>) => {
  //     const newValues = filtersRTKQ[key].values.map((_: AgeItemType) => ({
  //         ..._,
  //         checked: false,
  //     }));

  //      const newFilters = _getNewFilters({ key, newValues });
  //     _commonUpdate({ newFilters });
  // };

  // 2do: to optimize
  const clearAllFiltersByKeyRTKQ = () => {
    let newFilters: IFiltersRTKQ = {};

    for (let key in filtersRTKQ) {
      const newValues = filtersRTKQ[key].values.map((_: AgeItemType) => ({
        ..._,
        checked: false,
      }));

      newFilters = {
        ...filtersRTKQ,
        ...newFilters,
        [key]: {
          ...filtersRTKQ[key],
          values: newValues,
        },
      };
    }

    dispatch(mainMenuActions.setSelectedMenuItem(null));

    _commonUpdate({ newFilters });
  };

  // const updateFiltersRTKQ_0 = ({ key, item }: UpdateFiltersRTKQProps) => {
  //     const newValues = filtersRTKQ[key].values.map((_: AgeItemType) =>
  //         _.id === item.id ? item : _
  //     );

  //      const newFilters = _getNewFilters({ key, newValues });
  //     _commonUpdate({ newFilters });
  // };

  const updateFiltersRTKQ = ({ key, item }: UpdateFiltersRTKQProps) => {
    const copiedFilter: IFiltersRTKQ = JSON.parse(JSON.stringify(filtersRTKQ));
    const idx = copiedFilter[key].values.findIndex(
      (_: AgeItemType) => _.id === item.id
    );

    copiedFilter[key].values[idx].checked = item.checked;
    _commonUpdate({ newFilters: copiedFilter });
  };

  const updateFiltersRTKQColor = ({ key, item }: ChooseColorProps) => {
    const newValues = filtersRTKQ[key].values.map((_: AgeItemType) =>
      _.id === item.id ? item : _
    );

    const newFilters = _getNewFilters({ key, newValues });
    _commonUpdate({ newFilters });
  };

  const _getNewFilters = ({
    key,
    newValues,
  }: {
    key: string;
    newValues: AgeItemType[];
  }): IFiltersRTKQ => {
    const newFilters: IFiltersRTKQ = {
      ...filtersRTKQ,
      [key]: {
        ...filtersRTKQ[key],
        values: newValues,
      },
    };

    return newFilters;
  };

  const setFilterRTKQ = ({ filtersRTKQ }: { filtersRTKQ: IFiltersRTKQ }) => {
    _commonUpdate({ newFilters: filtersRTKQ });
  };

  const _commonUpdate = ({ newFilters }: { newFilters: IFiltersRTKQ }) => {
    dispatch(productsFiltersActions.setFiltersFromRTKQ(newFilters));

    updateQueryParamPage({
      newFilters,
      page: searchParams.get('page'),
    });
  };

  const updateQueryParamPage = ({
    newFilters = filtersRTKQ,
    page,
  }: {
    newFilters?: IFiltersRTKQ;
    page: string | null;
  }) => {
    const keyValue = buildKeyValueQueryParamsByFiltersRTKQ(newFilters);
    if (page) {
      keyValue.set('page', page);
    } else {
      keyValue.delete('page');
    }

    navigate('/');
    setSearchParams(keyValue);
  };

  const onChange = ({ key, item }: UpdateFiltersRTKQProps) => {
    updateFiltersRTKQ({ key, item });
  };

  const onChangeColor = ({ key, item }: ChooseColorProps) => {
    updateFiltersRTKQColor({ key, item });
  };

  return {
    filtersRTKQ,
    setFilterRTKQ,
    onChange,
    onChangeColor,
    updateQueryParamPage,
    // clearFiltersByKeyRTKQ,
    clearAllFiltersByKeyRTKQ,
    clearAndUpdateFiltersByKeyRTKQ,
  };
};
