import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SettingsSchema } from '../types/SettingsSchema';

const initialState: SettingsSchema = {
  settings: null,
};

export const settingsSlice = createSlice({
  name: 'productsFiltersSlice',
  initialState,
  reducers: {
    setSettingsRTKQ: (state, action: PayloadAction<SettingsSchema>) => {
      state.settings = { ...action.payload };
    },
  },
});

export const { actions: settingsActions, reducer: settingsReducer } =
  settingsSlice;
