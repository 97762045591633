import { TDanceexOptions } from '@/fsd/shared/types/scope/TypeProduct';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getAdminHeaders } from './getAdminHeaders';
import { showNotification } from './showNotification';

const baseUrl = import.meta.env.VITE_API_BASE_URL;

const ENDPOINT__UPDATE_PRODUCT_VISIBLE_STATUS =
  '/update_product_visible_status.php';

export const RTKQAdminApi = createApi({
  reducerPath: 'api/adminRequests',
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    updateProductDanceexOptions: builder.mutation<
      any | undefined, // ResponseData, // return data type
      // 2do: id повинно бути число
      { id: any; danceexOptions: TDanceexOptions } // RequestData // post params
    >({
      query: ({ id, danceexOptions }) => {
        const data = { id, danceex_options: danceexOptions };

        const headers = getAdminHeaders();

        return {
          url: ENDPOINT__UPDATE_PRODUCT_VISIBLE_STATUS,
          method: 'POST',
          body: JSON.stringify(data),
          headers,
        };
      },
      async onQueryStarted(objReq, { queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          const response = await result.data;

          showNotification(response, objReq);
        } catch (err) {
          console.log(err);
        }
      },
    }),
  }),
});

export const { useUpdateProductDanceexOptionsMutation } = RTKQAdminApi;
