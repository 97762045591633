export const RoutesNames = {
  Info: {
    DeliveryAndPayment: '/delivery-and-payment',
    Cooperation: '/cooperation',
    Contacts: '/contacts',
    Oferta: '/oferta',
    About: '/about',
  },
  KeyCRM: '/keycrm',
  ProductPage: '/product/',
  Checkout: '/checkout',
  Cart: '/cart',
  // : '/order-success-mono-callback',
  Order: {
    ID: '/order',
    Payment: 'payment', //test mono
    ThankYou: 'thank-you',
  },
};
