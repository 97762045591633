// import 'node_modules/react-checkbox-tree/lib/react-checkbox-tree.css';
import './react-checkbox-tree.css';

import { useEffect, useState } from 'react';

import CheckboxTree, { Node } from 'react-checkbox-tree';
import { useSearchParams } from 'react-router-dom';

import { FILTERS_NAME, getIsAdmin } from '@/const';
import { IFiltersRTKQ } from '@/fsd/entities/ProductFilters/model/types/filtersRTKQType';
import { useProductFilters } from '@/fsd/pages/ProductsPage/lib/hooks/useProductFilters';
import { useProductFiltersAdmin } from '@/fsd/pages/ProductsPage/lib/hooks/useProductFiltersAdmin';
import {
  getCheckboxFromUrl,
  getClone,
  getTreeNodes,
} from '@/fsd/shared/utils/utilsCheckboxFilter';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

// https://stackoverflow.com/questions/78094183/react-checkbox-tree-icons-not-shown-properly

/**
 * CustomReactCheckboxTree та CustomReactCheckboxTreeAdmin однакові. Єдина різниця, що для юзера використовується хук useProductFilters
 * @param props
 * @returns
 */
export function CustomReactCheckboxTree(props: { filterTypeName: string }) {
  const { filterTypeName } = props;

  const [checked, setChecked] = useState<Array<string>>([]);
  const [expanded, setExpanded] = useState<Array<string>>([]);
  const [nodes, setNodes] = useState<Array<Node>>([]);
  const [className, setClassName] = useState<string>('isHideRctCollapse');
  // const { filtersRTKQ, setFilterRTKQ } = useProductFilters();
  // const { filtersRTKQ } = useProductFilters();
  // const { filtersRTKQ, setFilterRTKQ } = useProductFiltersAdmin();
  const { filtersRTKQ, setFilterRTKQ } = getIsAdmin()
    ? useProductFiltersAdmin()
    : useProductFilters();
  const [searchParam] = useSearchParams();

  useEffect(() => {
    const checked = getCheckboxFromUrl({
      filtersRTKQ,
      searchParam,
      filterTypeName,
    });
    setChecked(checked);
  }, [searchParam]);

  useEffect(() => {
    let _isHideRctCollapse = className;
    const data = filtersRTKQ[filterTypeName].values;

    // Відбираємо лише рутові/кореневі елементи
    const rootElements = data.filter((i) => {
      // Категорії
      if ('parent_id' in i) {
        _isHideRctCollapse = '';
        const isRoot = i.parent_id === null;
        return isRoot;
      }
      // Чоловіки, Жінки
      else {
        _isHideRctCollapse = 'isHideRctCollapse';
        return true;
      }
    });

    const prepareNodes: Array<Node> = getTreeNodes(rootElements, data);

    setClassName(_isHideRctCollapse);
    setNodes(prepareNodes);
  }, [filtersRTKQ]);

  const onCheckHandler = (checked: string[]) => {
    setChecked(checked);
    updateStoreFilters(checked);
  };

  return (
    <div className={className}>
      <CheckboxTree
        nodes={nodes}
        checked={checked}
        expanded={expanded}
        onCheck={(checked) => onCheckHandler(checked)}
        onExpand={(expanded) => setExpanded(expanded)}
        showExpandAll={FILTERS_NAME.PRODUCT_CATEGORY === filterTypeName}
        icons={{
          check: <CheckBoxIcon />,
          uncheck: <CheckBoxOutlineBlankIcon />,
          halfCheck: <IndeterminateCheckBoxIcon />,
          expandClose: <ChevronRightIcon />,
          expandOpen: <ExpandMoreIcon />,
          expandAll: <UnfoldMoreIcon />,
          collapseAll: <UnfoldLessIcon />,
        }}
      />
    </div>
  );

  /**
   * Щоб при кліку на галочку чекбокса починав працювати фільтр
   * 2do: спробувати відмовитись від цієї функції. Замінивши її на відслідковування URL параметрів. Але в такому випадку є проблема. Коли в адмін режимі на сторінці товару - тоді немає ЮРЛ параметрів
   * @param checked
   */
  function updateStoreFilters(checked: string[]) {
    const changeableFiltersRTKQ: IFiltersRTKQ = getClone(filtersRTKQ);
    const categories = changeableFiltersRTKQ[filterTypeName].values;

    categories.forEach((_) => (_.checked = false));

    checked.forEach((_strId) => {
      const cat = categories.find((_) => _.id === +_strId);
      if (cat) {
        cat.checked = true;
      }
    });

    setFilterRTKQ({ filtersRTKQ: changeableFiltersRTKQ });
  }
}
