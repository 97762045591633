import { useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

import { getUpdatedFiltersByURL } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/config/restoreProductsFilterFromUrl';
import {
  useAppDispatch,
  useAppSelector,
} from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { getDynamicFilters } from '@/fsd/entities/MainMenu/model/selectors/mainMenuSelectors';
import { productsFiltersActions } from '@/fsd/entities/ProductFilters';
import { useGetFiltersQuery } from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQProductsListApi';
import { ListCheckboxesByRCT } from '@/fsd/pages/ProductsPage/impl/Aside/shared/widgets/ListCheckboxesByRCT';
import { useProductFilters } from '@/fsd/pages/ProductsPage/lib/hooks/useProductFilters';
import { classNames } from '@/fsd/shared/lib/classNames/classNames';
import { AgeItemTypeColor } from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter/types/AgeItemType';
import { Divider } from '@mui/material';

import { ChooseColor, ChooseSize } from '../../shared/components';
import { BtnClearFilters } from './BtnClearFilters';
import cls from './FiltersUser.module.scss';

export const FiltersUser = (props: { className?: string }) => {
  const { className } = props;
  const dynamicFilters = useAppSelector(getDynamicFilters);
  const dispatch = useAppDispatch();
  const [searchParam] = useSearchParams();
  const { onChange, onChangeColor } = useProductFilters();

  const {
    isLoading,
    isError,
    isSuccess,
    data: requestedFiltersRTKQ,
  } = useGetFiltersQuery(null);

  useEffect(() => {
    if (isSuccess) {
      const changeableFiltersRTKQ = JSON.parse(
        JSON.stringify(requestedFiltersRTKQ)
      );
      const { isFilterChanged, filtersRTKQ: filtersRTKQUpdatedByURL } =
        getUpdatedFiltersByURL(changeableFiltersRTKQ, searchParam);

      if (isFilterChanged) {
        dispatch(
          productsFiltersActions.setFiltersFromRTKQ(filtersRTKQUpdatedByURL)
        );
      }
    }
  }, [requestedFiltersRTKQ, searchParam]);

  if (isLoading) {
    return <span>Завантаження фільтрів...</span>;
  }

  if (isError) {
    return <span style={{ color: 'red' }}>Помилка завантаження фільтрів</span>;
  }

  if (!requestedFiltersRTKQ) {
    return <span>Дані для відображення ще не існують...</span>;
  }

  /**
     * 
     [
            [
                "age",
                {
                    "object_type": "checkbox",
                    "title": "Вік",
                    "values": [
                        {
                            "id": 0,
                            "title": "Вікова категорія.Діти",
                            "checked": false
                        },
                        {
                            "id": 1,
                            "title": "Вікова категорія.Дорослі",
                            "checked": false
                        }
                    ]
                }
            ],
            [
                "gender",
                {
                    "object_type": "checkbox",
                    "title": "Стать",
                    "values": [
                        {
                            "id": 0,
                            "title": "men",
                            "checked": false
                        },
                        {
                            "id": 1,
                            "title": "woomen",
                            "checked": false
                        },
                        {
                            "id": 2,
                            "title": "unisex",
                            "checked": false
                        }
                    ]
                }
            ],
            [
                "style",
                {
                    "object_type": "checkbox",
                    "title": "Стиль",
                    "values": [
                        {
                            "id": 0,
                            "title": "бальні",
                            "checked": false
                        },
                        {
                            "id": 1,
                            "title": "Hight Hills",
                            "checked": false
                        },
                        {
                            "id": 2,
                            "title": "Hip Hop",
                            "checked": false
                        }
                    ]
                }
            ]
        ]
     */
  const arrEntries = Object.entries(dynamicFilters);

  return (
    <div className={classNames(cls.FiltersUser, {}, [className])}>
      {/* <div style={{ display: 'flex', justifyContent: 'end', gap: '24px' }}>
        <MockDropdown
          label={'Сортувати'}
          list={['Найдорожчі', 'Найдешевші', 'інше']}
        />
      </div> */}
      <Divider />
      {arrEntries.map((_, idx) => {
        const key = _[0];
        const val = _[1];
        const title = val.title;
        const values = val.values;

        return (
          <div key={idx}>
            {val.object_type === 'checkbox' && (
              <ListCheckboxesByRCT title={title} filterTypeName={key} />
            )}
            {val.object_type === 'choose_color' && (
              <ChooseColor
                title={title}
                values={values as AgeItemTypeColor[]}
                filterTypeName={key}
                onChangeColor={onChangeColor}
              />
            )}
            {val.object_type === 'choose_size' && (
              <ChooseSize
                title={title}
                values={values}
                filterTypeName={key}
                onChange={onChange}
              />
            )}
          </div>
        );
      })}
      <Divider />
      <BtnClearFilters />
    </div>
  );
};
