import './Image.css';

import { ReactNode } from 'react';

import { useAppSelector } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { getSettingsRTKQ } from '@/fsd/entities/Settings/model/selectors/settingsSelectors';
import { TypeProduct } from '@/fsd/shared/types';

export const SimpleBadge = ({
  product,
  children,
}: {
  product: TypeProduct;
  children?: ReactNode;
}) => {
  const settings = useAppSelector(getSettingsRTKQ);

  if (!settings) {
    return 'Stutus Loding...';
  }

  const visiStatus = settings.product.visible_status.data.find(
    ({ id }: any) => id === product.danceex_options.visible_status_id
  );

  let bgc = '#ff00a8';
  if (visiStatus.id === 1) {
    bgc = 'red';
  }
  if (visiStatus.id === 2) {
    bgc = 'green';
  }

  // if (visiStatus.id === 1) {
  //   return <></>;
  // }

  return (
    <span
      style={{
        position: 'absolute',
        padding: '4px 8px',
        top: '10px',
        right: '4px',
        maxWidth: '75%',
        backgroundColor: bgc,
        borderRadius: '4px',
        border: '1px solid wheat',
        color: 'wheat',
        fontWeight: 'bold',
      }}
    >
      {children ? children : visiStatus.name}
      {/* {visiStatus.name} */}
    </span>
  );
};
