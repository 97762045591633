import { CartItemType } from '@/fsd/entities/cart/model/types/CartSchema';

import { TypeProduct } from '../../types';

export function getOfferByProdIdInCart(
  product: TypeProduct,
  cartItem: CartItemType
) {
  return product.danceex_options.offers.find(
    (o) => o.id === cartItem.item.offer_id
  );
}

export function getProductByProdIdInCart(
  products: TypeProduct[],
  cartItem: CartItemType
) {
  return products.find((p) => {
    const _color = p.danceex_options.color;
    return cartItem.item.product_id === p.id && cartItem.item.color === _color;
  });
}
