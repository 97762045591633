import { Link } from 'react-router-dom';

import { useClickMenuItem } from '@/fsd/shared/ui/Header/impl/hooks/useClickMenuItem';
import {
  AgeItemType,
  AgeItemTypeMenu,
} from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter/types/AgeItemType';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';

interface ItemMenuProps {
  item: AgeItemType;
}

/**
 * Елемент меню у якого немає підменю
 * @param props
 * @returns
 */
export function RadixItemMenu(props: ItemMenuProps) {
  const { item } = props;

  const _item = item as AgeItemTypeMenu;

  return (
    <>
      {/* <ItemMenu0 item={_item} /> */}
      <NavigationMenu.Item>
        <Link to={_item.link} className='NavigationMenuLink'>
          {item.name}
        </Link>
      </NavigationMenu.Item>
    </>
  );
}

// Проблема: при кліку на кореневий пункт меню "Взуття", "Одяг" в чекбоксТрі не підсвічується
// Потрібно отримати item із категорій по айді категорії
export function ItemMenu0(props: ItemMenuProps) {
  const { item } = props;
  const { onClickMenuItem } = useClickMenuItem();

  const _item = item as AgeItemTypeMenu;

  return (
    <>
      <NavigationMenu.Item>
        <NavigationMenu.Link
          className='NavigationMenuLink'
          href={_item.link}
          onClick={() => onClickMenuItem(_item)}
        >
          {item.name}
        </NavigationMenu.Link>
      </NavigationMenu.Item>
    </>
  );
}
