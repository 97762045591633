import './Left.css';

import { ItemInfo } from './impl/ItemInfo/ItemInfo';

// ref: https://allfordance.com/contact/
export const Left = () => {
    return (
        <div className='contacts__left'>
            <div className='item  active'>
                <div className='item__main'>
                    {/* <svg>
                        <use href='images/icons/mark-map.svg#icon'></use>
                    </svg> */}
                    <svg
                        width='27'
                        height='27'
                        viewBox='0 0 27 27'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                    >
                        <path
                            d='M19.6875 12.375C16.8915 12.375 14.625 14.6135 14.625 17.3749C14.625 18.9539 15.2578 20.1816 16.5234 21.2782C17.4156 22.0512 19.0386 23.7093 19.6875 24.75C20.3693 23.73 21.9594 22.0512 22.8516 21.2782C24.1172 20.1816 24.75 18.9539 24.75 17.3749C24.75 14.6135 22.4835 12.375 19.6875 12.375Z'
                            stroke='black'
                            strokeWidth='1.5'
                            strokeLinejoin='round'
                        />
                        <path
                            d='M15.2276 11.25C15.2276 9.07537 13.4647 7.3125 11.2901 7.3125C9.11541 7.3125 7.35254 9.07537 7.35254 11.25C7.35254 13.4246 9.11541 15.1875 11.2901 15.1875C11.7035 15.1875 12.1022 15.1237 12.4767 15.0056'
                            stroke='black'
                            strokeWidth='1.5'
                        />
                        <path
                            d='M13.5697 21.82C12.6448 22.8667 11.7881 23.9264 11.25 24.7499C10.0965 22.8576 7.21092 19.843 5.625 18.4376C3.375 16.4438 2.25 14.2116 2.25 11.3408C2.25 6.32006 6.27943 2.25 11.25 2.25C15.7202 2.25 19.4291 5.54179 20.1306 9.85642'
                            stroke='black'
                            strokeWidth='1.5'
                            strokeLinejoin='round'
                        />
                        <path
                            d='M19.6875 17.4375H19.6984'
                            stroke='black'
                            strokeWidth={3}
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                    </svg>
                    <div className='item__name'>
                        <p className='item__title'>Магазин</p>
                        <h4 className='item__address'>
                            м. Бровари, вул. Василя Симоненка, 30
                        </h4>
                        <a
                            className='item__href'
                            target='_blank'
                            rel='nofollow'
                            href='https://maps.google.com/maps?q=50.51693440873466,30.77693257479385&hl=uk-ua&t=m&z=15'
                        >
                            як проїхати
                        </a>
                    </div>
                    {/* <div className='item__open'>
                        <span className='item__opened'>-</span>
                        <span className='item__closed'>+</span>
                    </div> */}
                </div>
                <ItemInfo />
            </div>
        </div>
    );
};
