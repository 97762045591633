import { useAppDispatch } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { cartSliceActions } from '@/fsd/entities/cart';
import { CartItemType } from '@/fsd/entities/cart/model/types/CartSchema';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton } from '@mui/material';

export function Reduce({ cartItem }: { cartItem: CartItemType }) {
  const dispatch = useAppDispatch();

  const item = {
    product_id: cartItem.item.product_id,
    offer_id: cartItem.item.offer_id,
    color: cartItem.item.color,
  };

  const reduce = () => {
    dispatch(cartSliceActions.reduceProduct({ ...item }));
  };

  return (
    <IconButton
      aria-label='remove'
      onClick={reduce}
      disabled={cartItem.qty <= 0}
    >
      <RemoveIcon />
    </IconButton>
  );
}
