import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = import.meta.env.VITE_API_KEYCRM_BASE_URL;
const KeyCRM_GET_ORDER_STATUS = '/get_order_info.php';

export const RTKQKeyCrmApi = createApi({
  reducerPath: 'api/keycrm',
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    getKeyCrmOrderStatus: builder.query<
      any /* resp type */,
      any /* req type */
    >({
      keepUnusedDataFor: 0,
      query: (id) => {
        return KeyCRM_GET_ORDER_STATUS + `?order_id=${id}`;
      },
      async onQueryStarted(_id, { queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          const resp = result.data;

          console.log(resp);
        } catch (err) {
          console.log(err);
        }
      },
    }),
  }),
});

export const { useLazyGetKeyCrmOrderStatusQuery } = RTKQKeyCrmApi;
