import { useTitle } from '@/hooks';

import { SendCartToKeyCrm } from '../../utils/SendCartToKeyCrm';

export function RenderRows() {
  useTitle('Оформлення замовлення');

  return (
    <div className='ContentWrapper ContentWrapper-Vertical'>
      <h1 style={{ textAlign: 'center' }}>Оформлення замовлення</h1>
      <SendCartToKeyCrm />
    </div>
  );
}
