import { CartItemType } from '@/fsd/entities/cart/model/types/CartSchema';

import { Add } from './Add';
import { Delete } from './Delete';
import { Reduce } from './Reduce';

export function ActionButtons({ cartItem }: { cartItem: CartItemType }) {
  return (
    <span>
      <span>
        <Reduce cartItem={cartItem} />
        {cartItem.qty}
        <Add cartItem={cartItem} />
      </span>
      <Delete cartItem={cartItem} />
    </span>
  );
}
