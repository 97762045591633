import { useState } from 'react';

import { FilterIcon } from '@/assets/images/svgIcons/FilterIcon';
import { FiltersUser } from '@/fsd/pages/ProductsPage/impl/Aside/AsideFilters';
import { Box, Drawer } from '@mui/material';

export function MobileFilters() {
  const [hamburgerOpen, setHamburger] = useState<boolean>(false);

  return (
    <>
      <button onClick={() => setHamburger(!hamburgerOpen)}>
        <FilterIcon />
      </button>

      <Drawer
        open={hamburgerOpen}
        onClose={() => setHamburger(false)}
        anchor='right'
      >
        <Box sx={{ padding: '0 10px' }} role='presentation'>
          <FiltersUser />
        </Box>
      </Drawer>
    </>
  );
}
