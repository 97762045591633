import { Button } from '@mui/material';

type T = {
    name: string;
    onClick?: () => void;
};

export const MockButtonContained = ({ name, onClick }: T) => {
    return (
        <>
            <Button
                variant='contained'
                aria-label={name}
                fullWidth
                onClick={onClick}
            >
                {name}
            </Button>
        </>
    );
};
