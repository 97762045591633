import './ContactsPage.css';

import { useTitle } from '@/hooks';

import { Left } from './features/Left/Left';
import { Right } from './features/Right/Right';

export const ContactsPage = () => {
  useTitle('Контакти');

  return (
    <div className='ContentWrapper ContentWrapper-Vertical'>
      <div className='contact-locations'>
        <Left />
        <Right />
      </div>
    </div>
  );
};
