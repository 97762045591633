import { __IS_DEV__, getIsAdmin } from '@/const';

export const getAdminHeaders = () => {
  let headers = undefined;

  if (__IS_DEV__) {
    // Fix local development CORS
    return headers;
  }

  if (getIsAdmin()) {
    headers = {
      something: getIsAdmin() ? 'xxx' : '',
      'Content-Type': 'application/json; charset=UTF-8',
    };
  }

  return headers;
};
