import { useAppSelector } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { getCart } from '@/fsd/entities/cart';
import { cartProductsSelectors } from '@/fsd/entities/cartProducts/model/selectors/cartProductsSelectors';

import { getOfferByProdIdInCart, getProductByProdIdInCart } from '../cartUtils';

export function useGetCartSum() {
  const cartProducts = useAppSelector(cartProductsSelectors.products);
  const cartProds = useAppSelector(getCart);

  let sum = 0;

  cartProds.forEach((cartProd) => {
    const product = getProductByProdIdInCart(cartProducts, cartProd);
    if (product) {
      const offer = getOfferByProdIdInCart(product, cartProd);
      if (offer) {
        sum += cartProd.qty * offer.price;
      }
    }
  });

  return sum;
}
