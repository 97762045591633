import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

/**
 *
 * @param title is a page name
 */
export const usePageTracking = ({ title = '' }: { title: string }) => {
  const location = useLocation();

  useEffect(() => {
    const gtag = window.gtag;
    if (typeof gtag === 'function') {
      gtag('event', 'page_view', {
        title,
        page_path: location.pathname + location.search + location.hash,
        page_search: location.search,
        page_hash: location.hash,
      });
    }
  }, [location]);
};
