import './HeaderBottom.scss';

import { MAX_WIDTH_TABLET } from '@/const';
import { useMediaQuery } from '@mui/material';

import { Logo } from '../impl';
import RadixDesktopNavigationMenu from '../impl/NavigationMenu/RadixDesktopNavigationMenu';

export const HeaderBottom = () => {
  const isTablet = useMediaQuery(MAX_WIDTH_TABLET);

  return (
    <section aria-label='Top header content' className='Header-Bottom'>
      {isTablet && <div style={{ paddingTop: '54px' }}></div>}

      <div className='ContentWrapper '>
        <Logo />
        <div
          aria-label='Main website navigation'
          className='Header-MenuWrapper'
        >
          {!isTablet && <RadixDesktopNavigationMenu />}
          {/* <SearchDesktop /> */}
        </div>
      </div>
    </section>
  );
};
