import { TitleBlock } from '@/fsd/shared/ui/TitleBlock';

import { CustomReactCheckboxTree } from '../components/CustomReactCheckboxTree/CustomReactCheckboxTree';

export const ListCheckboxesByRCT = (props: {
  title: string;
  filterTypeName: string;
  className?: string;
}) => {
  const { className, title, filterTypeName } = props;

  return (
    <div className={className}>
      <TitleBlock>{title}</TitleBlock>
      <CustomReactCheckboxTree filterTypeName={filterTypeName} />
    </div>
  );
};
