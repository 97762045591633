import { useState } from 'react';

import { Link } from 'react-router-dom';

import { TBlock } from './types';

export interface TItem {
  item: TBlock;
}

export const Item = ({ item }: TItem) => {
  const { name, links } = item;
  const [isActive, setActive] = useState<boolean>(false);

  const toggle = () => setActive(!isActive);
  const c = ['Footer-LinksItem', isActive ? 'active' : ''].join(' ');

  return (
    <div className={c}>
      <h3 onClick={toggle}>{name}</h3>
      <ul>
        {links.map((_, idx) => (
          <li key={idx}>
            <Link to={_.link}>{_.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
