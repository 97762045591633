// import { useNavigate } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
} from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { mainMenuActions } from '@/fsd/entities/MainMenu';
import { productsFiltersActions } from '@/fsd/entities/ProductFilters';
import { getProductFiltersRTKQAdmin } from '@/fsd/entities/ProductFilters/model/selectors/productsFiltersSelectors';
import { IFiltersRTKQ } from '@/fsd/entities/ProductFilters/model/types/filtersRTKQType';
import { getSelectedOfferAdmin } from '@/fsd/entities/SelectedOffer';
import {
  getSelectedProductAdmin,
  selectedProductActions,
} from '@/fsd/entities/SelectedProduct';
import { getSelectedProductGroupAdmin } from '@/fsd/entities/SelectedProductGroup';
// import { buildQueryParamsByFiltersRTKQ } from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/buildQueryParamsByFiltersRTKQ';
import { AgeItemType } from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter';

import { ChooseColorProps } from '../../impl/Aside/shared/components/ChooseColor/ui/ChooseColorProps';
import { UpdateFiltersRTKQProps } from './types/UpdateFiltersRTKQProps';

export const useProductFiltersAdmin = () => {
  const dispatch = useAppDispatch();
  const filtersRTKQ = useAppSelector(getProductFiltersRTKQAdmin);
  const selectedProductAdmin = useAppSelector(getSelectedProductAdmin);
  const selectedProductGroupAdmin = useAppSelector(
    getSelectedProductGroupAdmin
  );
  const selectedSelectedOfferAdmin = useAppSelector(getSelectedOfferAdmin);

  const clearAndUpdateFiltersByKeyRTKQ = ({
    key,
    item,
  }: UpdateFiltersRTKQProps) => {
    let newValues: AgeItemType[];

    newValues = filtersRTKQ[key].values.map((_: AgeItemType) =>
      _.id === item.id
        ? { ...item }
        : {
            ..._,
            checked: false,
          }
    );

    const newFilters = _getNewFilters({ key, newValues });
    _commonUpdate({ newFilters });
  };

  // const clearFiltersByKeyRTKQ = ({
  //     key,
  // }: Pick<UpdateFiltersRTKQProps, 'key'>) => {
  //     const newValues = filtersRTKQ[key].values.map((_: AgeItemType) => ({
  //         ..._,
  //         checked: false,
  //     }));

  //      const newFilters = _getNewFilters({ key, newValues });
  //     _commonUpdate({ newFilters });
  // };

  // 2do: to optimize
  const clearAllFiltersByKeyRTKQ = () => {
    let newFilters: IFiltersRTKQ = {};

    for (let key in filtersRTKQ) {
      const newValues = filtersRTKQ[key].values.map((_: AgeItemType) => ({
        ..._,
        checked: false,
      }));

      newFilters = {
        ...filtersRTKQ,
        ...newFilters,
        [key]: {
          ...filtersRTKQ[key],
          values: newValues,
        },
      };
    }

    dispatch(mainMenuActions.setSelectedMenuItem(null));

    _commonUpdate({ newFilters });
  };

  // const updateFiltersRTKQ_0 = ({ key, item }: UpdateFiltersRTKQProps) => {
  //     const newValues = filtersRTKQ[key].values.map((_: AgeItemType) =>
  //         _.id === item.id ? item : _
  //     );

  //      const newFilters = _getNewFilters({ key, newValues });
  //     _commonUpdate({ newFilters });
  // };

  const updateFiltersRTKQ = ({ key, item }: UpdateFiltersRTKQProps) => {
    if (!filtersRTKQ[key].is_admin_mode_edit) {
      return;
    }

    const copiedFilter = JSON.parse(JSON.stringify(filtersRTKQ));
    const idx = copiedFilter[key].values.findIndex(
      (_: AgeItemType) => _.id === item.id
    );

    copiedFilter[key].values[idx].checked = item.checked;
    _commonUpdate({ newFilters: copiedFilter });

    if (item.checked) {
      // add
    } else {
      // remove
    }

    let f = (selectedProductAdmin?.danceex_options.filters || {}) as any;
    if (f[key]) {
      if (item.checked) {
        // add
        if (!f[key].includes(item.id)) {
          f = { ...f, [key]: [...f[key], item.id] };
        }
      } else {
        // remove
        // const index = f.indexOf(item);
        const index = f[key].indexOf(item.id);
        if (index > -1) {
          // only splice array when item is found
          f.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    } else {
      if (item.checked) {
        // add
        f = { ...f, [key]: [] };
        f[key].push(item.id);
      } else {
        // remove
      }
      // f = { ...f, [key]: [] };
      // f[key].push(item.id);
    }

    if (selectedProductAdmin) {
      const copiedProd = JSON.parse(JSON.stringify(selectedProductAdmin));
      copiedProd.danceex_options.filters = { ...f };

      dispatch(selectedProductActions.setSelectedProductAdmin(copiedProd));
    }
  };

  const updateFiltersRTKQColor = ({ key, item }: ChooseColorProps) => {
    if (!filtersRTKQ[key].is_admin_mode_edit) {
      return;
    }

    const newValues = filtersRTKQ[key].values.map((_: AgeItemType) =>
      _.id === item.id ? item : _
    );

    const newFilters = _getNewFilters({ key, newValues });
    _commonUpdate({ newFilters });
  };

  const _getNewFilters = ({
    key,
    newValues,
  }: {
    key: string;
    newValues: AgeItemType[];
  }): IFiltersRTKQ => {
    const newFilters: IFiltersRTKQ = {
      ...filtersRTKQ,
      [key]: {
        ...filtersRTKQ[key],
        values: newValues,
      },
    };

    return newFilters;
  };

  const setFilterRTKQ = ({ filtersRTKQ }: { filtersRTKQ: IFiltersRTKQ }) => {
    _commonUpdate({ newFilters: filtersRTKQ });
  };

  const _commonUpdate = ({ newFilters }: { newFilters: IFiltersRTKQ }) => {
    dispatch(productsFiltersActions.setFiltersFromRTKQAdmin(newFilters));

    console.log('selectedProductAdmin', selectedProductAdmin);
    console.log('selectedProductGroupAdmin', selectedProductGroupAdmin);
    console.log('selectedSelectedOfferAdmin', selectedSelectedOfferAdmin);

    // updateQueryParamPage({
    //   newFilters,
    //   page: searchParams.get('page'),
    // });
  };

  //   const updateQueryParamPage = ({
  //     newFilters = filtersRTKQ,
  //     page,
  //   }: {
  //     newFilters?: IFiltersRTKQ;
  //     page: string | null;
  //   }) => {
  //     const keyValue = buildKeyValueQueryParamsByFiltersRTKQ(newFilters);
  //     if (page) {
  //       keyValue.set('page', page);
  //     } else {
  //       keyValue.delete('page');
  //     }
  //     setSearchParams(keyValue);
  //   };

  const onChange = ({ key, item }: UpdateFiltersRTKQProps) => {
    updateFiltersRTKQ({ key, item });
  };

  const onChangeColor = ({ key, item }: ChooseColorProps) => {
    updateFiltersRTKQColor({ key, item });
  };

  return {
    filtersRTKQ,
    setFilterRTKQ,
    onChange,
    onChangeColor,
    // updateQueryParamPage,
    // clearFiltersByKeyRTKQ,
    clearAllFiltersByKeyRTKQ,
    clearAndUpdateFiltersByKeyRTKQ,
  };
};
