import { useAppDispatch } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { cartSliceActions } from '@/fsd/entities/cart';
import { CartItemType } from '@/fsd/entities/cart/model/types/CartSchema';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';

export function Delete({ cartItem }: { cartItem: CartItemType }) {
  const dispatch = useAppDispatch();

  const item = {
    product_id: cartItem.item.product_id,
    offer_id: cartItem.item.offer_id,
    color: cartItem.item.color,
  };

  const btnDelete = () => {
    dispatch(cartSliceActions.deleteProduct({ ...item }));
  };

  return (
    <IconButton aria-label='delete' onClick={btnDelete}>
      <DeleteIcon />
    </IconButton>
  );
}
