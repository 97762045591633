import './MenuMobile.css';

import { Key, useState } from 'react';

import { Hamburger } from '@/assets/images/svgIcons/Hamburger';
import {
  useGetFiltersQuery,
  useGetMainMenuQuery,
} from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQProductsListApi';
import { IFiltersRTKQ } from '@/fsd/entities/ProductFilters/model/types/filtersRTKQType';
import { Box, Drawer } from '@mui/material';

import { BuildMenu } from '../../../utils/BuildMenu';
import { Logo } from '../../Logo';
import { MuiList } from './MuiList/MuiList';

// Реалізаці частково скопійована з компоненту FiltersUser
export const MenuMobile = () => {
  // Отримати дані з Редакс. Для актуальності даних, цей спосіб не використовується. Використовується нижче реквест
  // const filtersRTKQ = useAppSelector(getMainMenuRTKQ);

  // Тимчасовий фікс.
  // Для того, щоб в Редаксі заповнити поле filtersRTKQ
  // Бо інакше воно порожнє. filtersRTKQ заповнювалось лише в десктоп версії
  useGetFiltersQuery(null);

  const {
    isLoading,
    isError,
    // isSuccess,
    data: requestedFiltersRTKQ,
  } = useGetMainMenuQuery(null);

  if (isLoading) {
    return <span>Завантаження меню...</span>;
  }

  if (isError) {
    return <span style={{ color: 'red' }}>Помилка завантаження меню</span>;
  }

  if (!requestedFiltersRTKQ) {
    return (
      <span style={{ color: 'red' }}>
        Не вдалось отримати дані для меню із сервера
      </span>
    );
  }

  return <Render requestedFiltersRTKQ={requestedFiltersRTKQ} />;
};

function Render({
  requestedFiltersRTKQ,
}: {
  requestedFiltersRTKQ: IFiltersRTKQ;
}) {
  const [hamburgerOpen, setHamburger] = useState<boolean>(false);

  const menuBuild = BuildMenu.buildRecursiveChild({
    menu: requestedFiltersRTKQ.main_menu.values,
    categories: requestedFiltersRTKQ.categories.values,
  });

  const DrawerList = (
    <Box sx={{ width: 250 }} role='presentation'>
      {hamburgerOpen && (
        <div aria-label='Main website navigation'>
          <ul className='Menu Menu_isLoaded Menu_isActive_'>
            {menuBuild.map((item: any, idx: Key | null | undefined) => (
              <MuiList item={item} key={idx} />
            ))}
          </ul>
        </div>
      )}
    </Box>
  );

  return (
    <>
      <button onClick={() => setHamburger(!hamburgerOpen)}>
        <Hamburger />
      </button>

      <Drawer
        open={hamburgerOpen}
        onClose={() => setHamburger(false)}
        sx={{ padding: '0 12px', textAlign: 'center' }}
      >
        <Logo />
        {DrawerList}
      </Drawer>
    </>
  );
}
