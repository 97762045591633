import { Typography } from '@mui/material';

export function EmptyCart() {
  return (
    <div className='ContentWrapper'>
      <Typography
        sx={{
          fontWeight: 'bold',
          fontSize: '24px',
        }}
      >
        Кошик порожній
      </Typography>
    </div>
  );
}
